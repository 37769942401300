var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step" }, [
    _c(
      "ul",
      _vm._l(_vm.data, function (item, index) {
        return _c("li", { key: index }, [
          index == 0
            ? _c("div", [
                _vm.type > 0
                  ? _c("img", {
                      attrs: {
                        src: require("../../assets/images/healthy/start_check.png"),
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("../../assets/images/healthy/start_nomal.png"),
                      },
                    }),
              ])
            : _vm._e(),
          index > 0 && index < _vm.data.length - 1
            ? _c("div", [
                _vm.type > 1 && index < _vm.type
                  ? _c("img", {
                      attrs: {
                        src: require("../../assets/images/healthy/mid_check.png"),
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("../../assets/images/healthy/mid_nomal.png"),
                      },
                    }),
              ])
            : _vm._e(),
          index == _vm.data.length - 1
            ? _c("div", [
                _vm.type == _vm.data.length
                  ? _c("img", {
                      attrs: {
                        src: require("../../assets/images/healthy/end_check.png"),
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("../../assets/images/healthy/end_nomal.png"),
                      },
                    }),
              ])
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(item))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }